import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { getSupplierAccountOptions, getTaxOptions, getBillItemTypeOptions } from '../selectors/dropdowns';
import Select from './Select';
import TextArea from 'react-textarea-autosize';
import DropdownMenu from './DropdownMenu';
import { formatMoney, parseMysqlDate, isNumeric } from '../utils';
import { createAddBill, createUpdateBill } from '../actions/bill';

import { BASE_ZINDEX } from '../popup-factory';

const getBreakdownSubtotal = breakdown => parseFloat(formatMoney(parseFloat(breakdown.quantity) * parseFloat(breakdown.unit_cost)));
const getBreakdownTaxes = (breakdown, taxes, getTaxInfo) => parseFloat(formatMoney(taxes.map(
  t => parseFloat(getTaxInfo(t.tax_id).percent) / 100
).map(
  t => getBreakdownSubtotal(breakdown) * t).reduce((total, amount) => total + amount, 0)
));
const getItemSubtotal = item => parseFloat(formatMoney(item.breakdowns.reduce((total, breakdown) => total + getBreakdownSubtotal(breakdown), 0)));
const getItemTaxes = (item, getTaxInfo) => parseFloat(formatMoney(item.breakdowns.reduce((total, breakdown) => total + getBreakdownTaxes(breakdown, item.taxes, getTaxInfo), 0)));
const getBillOrderSubtotal = po => parseFloat(formatMoney(parseFloat(formatMoney(po.items.filter(i => i.selected).reduce((total, item) => total + getItemSubtotal(item), 0))) + parseFloat(formatMoney(po.extra_items.filter(i => i.selected).reduce((total, item) => total + getBreakdownSubtotal(item), 0)))));
const getBillOrderTaxes = (po, getTaxInfo) => parseFloat(formatMoney(parseFloat(formatMoney(po.items.filter(i => i.selected).reduce((total, item) => total + getItemTaxes(item, getTaxInfo), 0))) + parseFloat(formatMoney(po.extra_items.filter(i => i.selected).reduce((total, item) => total + getBreakdownTaxes(item, item.taxes, getTaxInfo), 0)))));
const getBillSubtotal = bill => parseFloat(formatMoney(parseFloat(formatMoney(bill.purchase_orders.reduce((total, po) => total + getBillOrderSubtotal(po), 0))) + parseFloat(formatMoney(bill.orders.reduce((total, o) => total + getBillOrderSubtotal(o), 0)))));
const getBillTaxes = (bill, getTaxInfo) => parseFloat(formatMoney(parseFloat(formatMoney(bill.purchase_orders.reduce((total, po) => total + getBillOrderTaxes(po, getTaxInfo), 0))) + parseFloat(formatMoney(bill.orders.reduce((total, o) => total + getBillOrderTaxes(o, getTaxInfo), 0)))));

class BillItemBreakdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      breakdown: this.props.breakdown
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      breakdown: nextProps.breakdown
    });
  }

  handleChange(field) {
    return e => {
      if (this.isValueValid(field, e.target.value)) {
        this.setState({
          breakdown: Object.assign({}, this.state.breakdown, {[field]: e.target.value})
        });
      } else {
        this.setState({
          breakdown: Object.assign({}, this.state.breakdown, {[field]: e.target.value})
        });
      }
    };
  }

  handleBlur(field) {
    return e => {
      if (this.isValueValid(field, e.target.value)) {
        const breakdown = Object.assign({}, this.state.breakdown, {[field]: this.formatValue(field, e.target.value)});
        this.props.onUpdate(
          breakdown
        );
      } else {
        this.setState({
          breakdown: this.props.breakdown
        });
      }
    };
  }

  isValueValid(field, value) {
    switch (field) {
      case 'quantity':
        return isNumeric(value) && parseFloat(value) === parseInt(value, 10);
      case 'unit_cost':
        return isNumeric(value);
    }
    return true;
  }

  formatValue(field, value) {
    switch (field) {
      case 'quantity':
        return parseFloat(value).toFixed(0);
      case 'unit_cost':
        return parseFloat(value).toFixed(4);
    }
    return value;
  }

  render() {
    const { breakdown } = this.state;
    const { taxes, getTaxInfo } = this.props;
    const subtotal = getBreakdownSubtotal(breakdown);
    const tax_amount = getBreakdownTaxes(breakdown, taxes, getTaxInfo);
    const total = subtotal + tax_amount;
    return (
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{breakdown.size_name} / {breakdown.color_name}</td>
        <td><input type="text" value={breakdown.quantity} onChange={this.handleChange('quantity')} onBlur={this.handleBlur('quantity')} /></td>
        <td><input type="text" value={breakdown.unit_cost} onChange={this.handleChange('unit_cost')} onBlur={this.handleBlur('unit_cost')} /></td>
        <td>${formatMoney(subtotal)}</td>
        <td>&nbsp;</td>
        <td>${formatMoney(total)}</td>
        <td>&nbsp;</td>
      </tr>
    );
  }
}

class BillItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      selected: true,
      item: this.props.item
    };

    this.handleToggleCollapse = this.handleToggleCollapse.bind(this);
    this.handleToggleSelected = this.handleToggleSelected.bind(this);
    this.onUpdateBreakdown = this.onUpdateBreakdown.bind(this);
    this.onChangeTax = this.onChangeTax.bind(this);
    this.onDeleteTax = this.onDeleteTax.bind(this);
    this.handleAddTax = this.handleAddTax.bind(this);
  }

  handleToggleSelected(e) {
    const item = Object.assign({}, this.state.item, { selected: !this.state.item.selected });
    this.setState({ item });
    this.props.onUpdate(item);
  }

  handleToggleCollapse(e) {
    this.setState({ collapsed: !this.state.collapsed });
  }

  onUpdateBreakdown(breakdown) {
    const item = Object.assign({}, this.state.item, {
      breakdowns: this.state.item.breakdowns.map(b => {
        if (b.bill_item_id === breakdown.bill_item_id) {
          return breakdown;
        } else {
          return b;
        }
      })
    });
    this.setState({ item });
    this.props.onUpdate(item);
  }

  onChangeTax(index) {
    return tax_id => {
      const taxes = this.state.item.taxes.map((t, i) => i === index ? Object.assign({}, t, { tax_id }) : t);
      const item = Object.assign({}, this.state.item, { taxes });
      this.setState({ item });
      this.props.onUpdate(item);
    };
  }

  onDeleteTax(index) {
    return () => {
      const taxes = this.state.item.taxes.filter((t, i) => i !== index);
      const item = Object.assign({}, this.state.item, { taxes });
      this.setState({ item });
      this.props.onUpdate(item);
    };
  }

  handleAddTax(e) {
    e.preventDefault();
    const taxes = this.state.item.taxes.concat({ tax_id: this.props.default_tax_id });
    const item = Object.assign({}, this.state.item, { taxes });
    this.setState({ item });
    this.props.onUpdate(item);
  }

  render() {
    const { item } = this.state;
    const { taxes, getTaxInfo } = this.props;
    const subtotal = getItemSubtotal(item);
    const tax_amount = getItemTaxes(item, getTaxInfo);
    const total = subtotal + tax_amount;

    return (
      <tbody>
        <tr>
          <td>
            <input type="checkbox" onChange={this.handleToggleSelected} checked={this.state.item.selected}></input>
          </td>
          <td>
            {item.product_code}
          </td>
          <td>
            {item.product_name}
          </td>
          <td>
            {item.breakdowns.length ? parseFloat(item.quantity).toFixed(0) : null}
          </td>
          <td>
          </td>
          <td>
            ${formatMoney(subtotal)}
          </td>
          <td>
            {item.taxes.map((t, index) =>
              <span key={index}>{getTaxInfo(t.tax_id).label} {parseFloat(getTaxInfo(t.tax_id).percent).toFixed(1)}%<br /></span>
            )}
          </td>
          <td>
            ${formatMoney(total)}
          </td>
          <td>
            <a className="button" onClick={this.handleToggleCollapse}>View / Edit Breakdown</a>
          </td>
        </tr>
        {!this.state.collapsed ?
          item.breakdowns.map((b, index) =>
            <BillItemBreakdown key={index} breakdown={b} taxes={item.taxes} onUpdate={this.onUpdateBreakdown} getTaxInfo={getTaxInfo} />
          ).concat(
            <tr key="taxes">
              <td colSpan="2">&nbsp;</td>
              <td colSpan="7">Taxes: <a className="button" onClick={this.handleAddTax}>Add Tax</a></td>
            </tr>
          ).concat(item.taxes.map((t, index) =>
            <BillItemTax key={item.breakdowns.length + index} tax_amount={t} taxes={taxes} onChange={this.onChangeTax(index)} onDelete={this.onDeleteTax(index)} />
          )) : null}
      </tbody>
    );
  }
}

class ExtraBillItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      selected: true,
      show_dropdown: false,
      item: this.props.item
    };

    this.handleToggleCollapse = this.handleToggleCollapse.bind(this);
    this.handleToggleSelected = this.handleToggleSelected.bind(this);
    this.onChangeTax = this.onChangeTax.bind(this);
    this.onDeleteTax = this.onDeleteTax.bind(this);
    this.handleAddTax = this.handleAddTax.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onSelectBillItemType = this.onSelectBillItemType.bind(this);
  }

  handleChange(field) {
    return e => {
      const show_dropdown = 'item_code' === field;
      this.setState({
        item: Object.assign({}, this.state.item, {[field]: e.target.value}),
        show_dropdown
      });
    };
  }

  handleBlur(field) {
    return e => {
      if (this.isValueValid(field, e.target.value)) {
        const item = Object.assign({}, this.state.item, {[field]: this.formatValue(field, e.target.value)});
        this.props.onUpdate(item);
      } else {
        this.setState({
          item: this.props.item,
          show_dropdown: false
        });
      }
    };
  }

  isValueValid(field, value) {
    switch (field) {
      case 'quantity':
        return isNumeric(value) && parseFloat(value) === parseInt(value, 10);
      case 'unit_cost':
        return isNumeric(value);
    }
    return true;
  }

  formatValue(field, value) {
    switch (field) {
      case 'quantity':
        return parseFloat(value).toFixed(0);
      case 'unit_cost':
        return parseFloat(value).toFixed(4);
    }
    return value;
  }

  handleToggleSelected(e) {
    const item = Object.assign({}, this.state.item, { selected: !this.state.item.selected });
    this.setState({ item, show_dropdown: false });
    this.props.onUpdate(item);
  }

  handleToggleCollapse(e) {
    this.setState({ collapsed: !this.state.collapsed, show_dropdown: false });
  }

  onChangeTax(index) {
    return tax_id => {
      const taxes = this.state.item.taxes.map((t, i) => i === index ? Object.assign({}, t, { tax_id }) : t);
      const item = Object.assign({}, this.state.item, { taxes });
      this.setState({ item, show_dropdown: false });
      this.props.onUpdate(item);
    };
  }

  onDeleteTax(index) {
    return () => {
      const taxes = this.state.item.taxes.filter((t, i) => i !== index);
      const item = Object.assign({}, this.state.item, { taxes });
      this.setState({ item, show_dropdown: false });
      this.props.onUpdate(item);
    };
  }

  handleAddTax(e) {
    e.preventDefault();
    const taxes = this.state.item.taxes.concat({ tax_id: this.props.default_tax_id });
    const item = Object.assign({}, this.state.item, { taxes });
    this.setState({ item, show_dropdown: false });
    this.props.onUpdate(item);
  }

  onSelectBillItemType(bill_item_type_id) {
    const item = Object.assign({}, this.state.item, {
      bill_item_type_id,
      item_code: this.props.bill_item_types.filter(o => o.key === bill_item_type_id)[0].value
    });
    this.setState({ item, show_dropdown: false });
    this.props.onUpdate(item);
  }

  render() {
    const { item } = this.state;
    const { taxes, getTaxInfo } = this.props;
    const subtotal = getBreakdownSubtotal(item);
    const tax_amount = getBreakdownTaxes(item, item.taxes, getTaxInfo);
    const total = subtotal + tax_amount;

    const bill_item_types = this.props.bill_item_types.filter(o => o.value.toLowerCase().indexOf(item.item_code.toLowerCase()) > -1).map(o => Object.assign({}, o, { onClick: e => { e.preventDefault(); this.onSelectBillItemType(o.key); } }));

    return (
      <tbody>
        <tr>
          <td>
            <input type="checkbox" onChange={this.handleToggleSelected} checked={this.state.item.selected}></input>
          </td>
          <td style={{ position: 'relative' }}>
            <input type="text" value={item.item_code} onChange={this.handleChange('item_code')} onBlur={this.handleBlur('item_code')}/>
            { this.state.show_dropdown ? <DropdownMenu options={bill_item_types} /> : null }
          </td>
          <td>
            <input type="text" value={item.item_name} onChange={this.handleChange('item_name')} onBlur={this.handleBlur('item_name')}/>
          </td>
          <td>
            <input type="text" value={item.quantity} onChange={this.handleChange('quantity')} onBlur={this.handleBlur('quantity')}/>
          </td>
          <td>
            $<input type="text" value={item.unit_cost} onChange={this.handleChange('unit_cost')} onBlur={this.handleBlur('unit_cost')}/>
          </td>
          <td>
            ${formatMoney(subtotal)}
          </td>
          <td>
            {item.taxes.map((t, index) =>
              <span key={index}>{getTaxInfo(t.tax_id).label} {parseFloat(getTaxInfo(t.tax_id).percent).toFixed(1)}%<br /></span>
            )}
          </td>
          <td>
            ${formatMoney(total)}
          </td>
          <td>
            <a className="button" onClick={this.handleToggleCollapse}>View / Edit Breakdown</a>
          </td>
        </tr>
        {!this.state.collapsed ?
          [<tr key="taxes">
              <td colSpan="2">&nbsp;</td>
              <td colSpan="7">Taxes: <a className="button" onClick={this.handleAddTax}>Add Tax</a></td>
            </tr>
          ].concat(item.taxes.map((t, index) =>
            <BillItemTax key={index} tax_amount={t} taxes={taxes} onChange={this.onChangeTax(index)} onDelete={this.onDeleteTax(index)} />
          )) : null}
      </tbody>
    );
  }
}

class BillItemTax extends Component {

  constructor(props) {
    super(props);

    this.handleDeleteTax = this.handleDeleteTax.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleDeleteTax(e) {
    e.preventDefault();
    this.props.onDelete();
  }

  onChange(value) {
    this.props.onChange(value);
  }

  render() {
    const { taxes, tax_amount } = this.props;
    return (
      <tr>
        <td colSpan="2">&nbsp;</td>
        <td>
          <a onClick={this.handleDeleteTax}>&times;</a>
          <Select options={taxes} value={tax_amount.tax_id} change={this.onChange} />
        </td>
        <td colSpan="6">&nbsp;</td>
      </tr>
    );
  }
}

class BillSection extends Component {

  constructor(props) {
    super(props);

    this.handleCreateItem = this.handleCreateItem.bind(this);
  }

  handleCreateItem(e) {
    e.preventDefault();
    this.props.onCreate(this.props.purchase_order_id, this.props.order_id, this.props.order_form_number);
  }

  render() {
    const { items, extra_items, taxes, bill_item_types, getTaxInfo, default_tax_id, onUpdate, onUpdateExtra } = this.props;
    const title = this.props.purchase_order_id ?
      <a target="_blank" href={`/purchase_order.php?id=${this.props.purchase_order_id}`}>{`PO# ${this.props.form_number}`}</a> :
      <a target="_blank" href={`sales-order/${this.props.form_number}`}>{`Sales Order# ${this.props.form_number}`}</a>;

    return (
      <table>
        <thead>
          <tr>
            <th colSpan="7">{title}</th>
            <th colSpan="9">
              <a className="button" onClick={this.handleCreateItem} style={{width: '100%'}}>Add Bill Item</a>
            </th>
          </tr>
          <tr>
            <th>
            </th>
            <th style={{width: '100px'}}>
              Code
            </th>
            <th>
              Item Name
            </th>
            <th style={{width: '70px'}}>
              Qty
            </th>
            <th style={{width: '100px'}}>
              Unit Cost
            </th>
            <th>
              Subtotal
            </th>
            <th>
              Tax
            </th>
            <th>
              Total
            </th>
            <th>
            </th>
          </tr>
        </thead>
        {items.map(bi =>
          <BillItem key={bi.bill_item_id} item={bi} taxes={taxes} getTaxInfo={getTaxInfo} default_tax_id={default_tax_id} onUpdate={onUpdate} />
        )}
        {extra_items.map(i =>
          <ExtraBillItem key={i.bill_item_id} item={i} taxes={taxes} bill_item_types={bill_item_types} getTaxInfo={getTaxInfo} default_tax_id={default_tax_id} onUpdate={onUpdateExtra} />
        )}
      </table>
    );
  }
}

class EditBillPopup extends Component {

  constructor(props) {
    super(props);

    const bill = this.props.bill || {};
    this.state = {
      is_new: !this.props.bill.items,
      default_tax_id: this.props.taxes[0].key,
      bill: Object.assign({}, bill, {
        bill_date_due: parseMysqlDate(bill.bill_date_due),
        bill_date_billdate: parseMysqlDate(bill.bill_date_billdate),
        items: (bill.items || []).map(i => Object.assign({}, i, { selected: true })),
        purchase_orders: (bill.purchase_orders || []).map(po => Object.assign({}, po, {
          items: (po.items || []).map(i => Object.assign({}, i, { selected: true })),
          extra_items: (po.extra_items || []).map(i => Object.assign({}, i, { selected: true }))
        })),
        orders: (bill.orders || []).map(o => Object.assign({}, o, {
          items: (o.items || []).map(i => Object.assign({}, i, { selected: true })),
          extra_items: (o.extra_items || []).map(i => Object.assign({}, i, { selected: true }))
        }))
      })
    };

    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeDefaultTax = this.onChangeDefaultTax.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.onUpdateExtraItem = this.onUpdateExtraItem.bind(this);
    this.onCreateItem = this.onCreateItem.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const bill = nextProps.bill || { items: []};
    this.setState({
      default_tax_id: nextProps.taxes[0].key,
      bill: Object.assign({}, nextProps.bill, {
        bill_date_due: parseMysqlDate(bill.bill_date_due),
        bill_date_billdate: parseMysqlDate(bill.bill_date_billdate),
        items: bill.items.map(i => Object.assign({}, i, { selected: true })),
        purchase_orders: bill.purchase_orders.map(po => Object.assign({}, po, {
          items: po.items.map(i => Object.assign({}, i, { selected: true })),
          extra_items: po.extra_items.map(i => Object.assign({}, i, { selected: true }))
        })),
        orders: bill.orders.map(o => Object.assign({}, o, {
          items: o.items.map(i => Object.assign({}, i, { selected: true })),
          extra_items: o.extra_items.map(i => Object.assign({}, i, { selected: true }))
        }))
      })
    });
  }

  handleChange(field) {
    return e => this.onChange(field)(e.target.value);
  }

  onChange(field) {
    return value => {
      this.setState({ bill: Object.assign({}, this.state.bill, {[field]: value}) });
    };
  }

  onChangeDefaultTax(default_tax_id) {
    this.setState({ default_tax_id });
  }

  onUpdateItem(item) {
    const bill = Object.assign({}, this.state.bill, {
      items: this.state.bill.items.map(i => {
        if (i.item_id === item.item_id) {
          return item;
        }
        return i;
      }),
      purchase_orders: this.state.bill.purchase_orders.map(po =>
        Object.assign({}, po, {
          items: po.items.map(i => {
            if (i.item_id === item.item_id) {
              return item;
            }
            return i;
          })
        })
      ),
      orders: this.state.bill.orders.map(o =>
        Object.assign({}, o, {
          items: o.items.map(i => {
            if (i.item_id === item.item_id) {
              return item;
            }
            return i;
          })
        })
      )
    });
    this.setState({ bill });
  }

  onUpdateExtraItem(item) {
    const bill = Object.assign({}, this.state.bill, {
      items: this.state.bill.items.map(i => {
        if (i.item_id === item.item_id) {
          return item;
        }
        return i;
      }),
      purchase_orders: this.state.bill.purchase_orders.map(po =>
        Object.assign({}, po, {
          extra_items: po.extra_items.map(i => {
            if (i.bill_item_id === item.bill_item_id) {
              return item;
            }
            return i;
          })
        })
      ),
      orders: this.state.bill.orders.map(o =>
        Object.assign({}, o, {
          extra_items: o.extra_items.map(i => {
            if (i.bill_item_id === item.bill_item_id) {
              return item;
            }
            return i;
          })
        })
      )
    });
    this.setState({ bill });
  }

  onCreateItem(purchase_order_id, order_id, order_form_number) {
    const item = {
      purchase_order_id,
      order_id,
      order_form_number,
      quantity: 1,
      unit_cost: 0,
      item_code: '',
      item_name: '',
      bill_item_type_id: '',
      taxes: [{ tax_id: this.state.default_tax_id }],
      selected: true,
      extra: true
    };
    const bill = Object.assign({}, this.state.bill, {
      items: this.state.bill.items.concat(item),
      purchase_orders: this.state.bill.purchase_orders.map(po =>
        po.purchase_order_id === purchase_order_id ?
          Object.assign({}, po, { extra_items: po.extra_items.concat(item) }) :
          po
      ),
      orders: this.state.bill.orders.map(o =>
        o.order_id === order_id ?
          Object.assign({}, o, { extra_items: o.extra_items.concat(item) }) :
          o
      )
    });
    this.setState({ bill });
  }

  render() {
    const { onClosePopup, supplier_accounts, taxes, bill_item_types, getTaxInfo, onUpdateBill, onAddBill, index } = this.props;
    const { bill } = this.state;
    const subtotal = getBillSubtotal(bill);
    const tax_amount = getBillTaxes(bill, getTaxInfo);
    const total = subtotal + tax_amount;
    return (
      <div id="bills-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="small-12 columns">
          <h3>{this.props.bill.items ? 'Edit' : 'Add'} Bill</h3>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <div className="row">
              <div className="small-12 medium-6 columns">
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Supplier</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    <Select options={supplier_accounts} value={this.state.bill.division_id} change={this.onChange('supplier_id')} />
                  </div>
                </div>
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Invoice #</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    <input type="text" value={this.state.bill.bill_reference_number} onChange={this.handleChange('bill_reference_number')}></input>
                  </div>
                </div>
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Invoice Date</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    <input value={this.state.bill.bill_date_billdate} onChange={this.handleChange('bill_date_billdate')} type="date" placeholder="yyyy-mm-dd"></input>
                  </div>
                </div>
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Due Date</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    <input value={this.state.bill.bill_date_due} onChange={this.handleChange('bill_date_due')} type="date" placeholder="yyyy-mm-dd"></input>
                  </div>
                </div>
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Notes</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    <TextArea value={this.state.bill.bill_memo} onChange={this.handleChange('bill_memo')}></TextArea>
                  </div>
                </div>
              </div>
              <div className="small-12 medium-6 columns">
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Subtotal</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    ${formatMoney(subtotal)}
                  </div>
                </div>
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Global Tax</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    <Select options={taxes} value={this.state.default_tax_id} change={this.onChangeDefaultTax} withMarginBottom />
                  </div>
                </div>
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Total Tax</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    ${formatMoney(tax_amount)}
                  </div>
                </div>
                <div className="row">
                  <div className="small-4 medium-6 large-4 columns">
                    <strong>Amount Due</strong>
                  </div>
                  <div className="small-8 medium-6 large-8 columns">
                    ${formatMoney(total)}
                  </div>
                </div>
                <div className="row">
                  <div className="small-12 columns">
                    <a className="expanded button" onClick={e => { e.preventDefault(); this.state.is_new ? onAddBill(this.state.bill) : onUpdateBill(this.state.bill);}}>Save</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ overflowY: 'auto', height: '300px' }}>
              {bill.purchase_orders.map(po =>
                <BillSection key={po.purchase_order_id} purchase_order_id={po.purchase_order_id} order_id={po.order_id} form_number={po.form_number} order_form_number={po.order_form_number} items={po.items} extra_items={po.extra_items} taxes={taxes} bill_item_types={bill_item_types} getTaxInfo={getTaxInfo} default_tax_id={this.state.default_tax_id} onUpdate={this.onUpdateItem} onCreate={this.onCreateItem} onUpdateExtra={this.onUpdateExtraItem} />
              )}
              {bill.orders.map(o =>
                <BillSection key={o.order_id} order_id={o.order_id} form_number={o.form_number} order_form_number={o.form_number} items={o.items} extra_items={o.extra_items} taxes={taxes} bill_item_types={bill_item_types} getTaxInfo={getTaxInfo} default_tax_id={this.state.default_tax_id} onUpdate={this.onUpdateItem} onCreate={this.onCreateItem} onUpdateExtra={this.onUpdateExtraItem} />
              )}
            </div>
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    supplier_accounts: getSupplierAccountOptions(state),
    taxes: getTaxOptions(state),
    getTaxInfo: tax_id => state.entities.taxes[tax_id],
    bill_item_types: getBillItemTypeOptions(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onUpdateBill: bill => dispatch(createUpdateBill(bill)).then(dispatch(closePopup())),
    onAddBill: bill => dispatch(createAddBill(bill)).then(dispatch(closePopup()))
  };
};

const ConnectedEditBillPopup = connect(mapStateToProps, mapDispatchToProps)(EditBillPopup);
export default ConnectedEditBillPopup;
